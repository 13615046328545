:root {
    --kanban-list-padding: 12px;
    --kanban-list-radius: 8px;
    --kanban-list-header-height: 36px;
}

.kanban-view {
    /* padding-left: 25px;
    padding-right: 25px; */
    height: calc(100vh - 198px) !important;
    width: 100%;
}

.kanban-list-wrapper {
    padding-right: 12px;
    border-bottom-left-radius: var(--kanban-list-radius);
    border-bottom-right-radius: var(--kanban-list-radius);
    height: max-content;
}

.kanban-list-header-wrapper,
.kanban-list-header {
    border-top-left-radius: var(--kanban-list-radius);
    border-top-right-radius: var(--kanban-list-radius);
}

.kanban-list-header {
    padding-top: 40px;
    color: #000;
    height: var(--kanban-list-header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.kanban-list-body>div::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    background-color: #f9f9f9;
}

.kanban-list-body>div::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.kanban-list-body {
    /* border: 1px solid #e6e6e6; */
}
.kanban-list-body,
.kanban-list-body>div {
    height: calc(100% - var(--kanban-list-header-height));
    border-bottom-left-radius: var(--kanban-list-radius);
    border-bottom-right-radius: var(--kanban-list-radius);
}


/* .kanban-list-body-virtual-item {
    padding-inline: var(--kanban-card-padding);
    overflow: hidden;
}

.kanban-list-body>div>div .kanban-list-body-virtual-item {
    padding-top: var(--kanban-card-padding);
}

.kanban-list-body>div>div .kanban-list-body-virtual-item:last-child {
    padding-bottom: var(--kanban-card-padding);
} */

.kanban-card-list-dropzone {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #e0e0e0;
}

.kanban-card-list-dropzone.center {
    border: 2px dashed #6d41be;
    color: #6d41be;
}

.kanban-list-virtual-item {
    padding: 8px 8px 0;
    position: relative;
}

.kanban-list-body [data-item] .kanban-list-virtual-item:last-child {
    padding-bottom: 8px;
}

.kanban-card-wrapper {
    position: relative;
    padding: 6px;
    background-color: #ffffff;
    border: 1px solid #e8e6e6;
    border-radius: 8px;
}

.kanban-card-title {
    color: #000;
}

.kanban-card {
    /* position: relative; */
    display: grid;
}

.kanban-card>div:not(:last-child) {
    margin-bottom: 6px;
}

.kanban-card-tags {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
}

.kanban-card-tags .ant-tag {
    margin-inline-end: 0;
    padding-inline: 4px;
    margin-bottom: 2px;
}

.kanban-card-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.kanban-card-footer>div {
    display: inline-block;
}

.kanban-card-date,
.kanban-card-number {
    font-size: 12px;
}

.kanban-card-date {
    margin-right: auto;
}

.kanban-card-person {
    margin-left: auto;
    display: flex !important;
}

/* .kanban-card-edit {
    position: absolute;
    top: 0;
    right: 0;
} */

/* .kanban-card-list-item {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 1rem;
    height: var(--kanban-card-height);
}

.kanban-card-list-item-field {
    text-overflow: ellipsis;
    grid-column: span 2 / span 2;
    text-align: center;
} */

.kanban-settings {
    width: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}

.kanban-columns-list {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.kanban-columns-list label {
    margin-inline-start: 0 !important;
}

.kanban-modal-wrapper {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.kanban-fields {
    grid-column: span 2 / span 2;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 1rem;
}

.kanban-field-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    height: 40px;
}

.kanban-field-name {
    height: 100%;
    display: flex;
    align-items: center;
    color: #a7a5a5;
    /* padding-left: 1rem; */
}

.kanban-editable-cell {
    position: relative;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    width: 100%;
    border-radius: 6px;
    grid-column: span 2 / span 2;
}

.kanban-editable-cell.cell-outline {
    border: 2px solid #e0e0e0;
    padding-inline: 8px;
}

.kanban-editable-cell :where(.ant-input-number, .ant-input, .boards-popover-cell) {
    height: 100%;
    border-color: transparent;
}

.kanban-detail {
    grid-column: span 3 / span 3;
}

.kanban-card-dropzone {
    position: absolute;
    left: 50%;
    width: calc(100% - 16px);
    transform: translateX(-50%);
    height: 4px;
    background-color: #6d41be;
    border-radius: 8px;
    z-index: 10;
}

.kanban-card-dropzone.top {
    top: 2px;
}

.kanban-card-dropzone.bottom {
    bottom: -6px;
}

.kanban-list-body [data-item] .kanban-card-dropzone.bottom {
    bottom: 0;
}

