:root {
    --board-sidebar-width: 255px;
    --board-border-color: #e4e2e2;
    --board-body-inline-padding: 1rem;
    --board-group-indicator-width: 6px;
    --board-action-cell-width: 2rem;
}

.board-sidebar {
    position: fixed;
    height: 100%;
    width: 255px;
    z-index: 12;
}

.board-view-loading {
    background-color: white;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.board-body {
    /* margin-left: var(--board-sidebar-width); */
    width: 100%;
}

.board-editable-field {
    padding: 0 5px;
    height: inherit;
    width: 100%;
    display: flex;
    padding: 0 !important;
    background-color: #fff !important;
    align-items: center;
}

.board-editable-field .ant-input-borderless {
    background: #fff !important;
}

.board-editable-field .ant-input-number-input {
    padding: inherit !important;
}

.board-editable-field.small {
    color: #999;
    font-size: 14px !important;
}

.board-editable-field.medium {
    font-size: 20px !important;
}

.board-editable-field.large {
    font-size: 18px !important;
    font-weight: bold;
}

.board-body-header {
    position: fixed;
    right: 0;
    z-index: 10;
    background-color: white;
    transition: all 0.3s ease;
}

.board-body-header .ant-menu-horizontal {
    border: none;
    line-height: 32px;
    padding-top: 5px;
    padding-left: 15px;
    margin-right: 0px;
}

.board-body-header .ant-menu-horizontal>li {
    padding-inline: 0px;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    height: 27px;
    margin-right: 8px;
}

.board-body-header .ant-menu-horizontal>li::after {
    inset-inline: 5px !important;
    bottom: 5px !important;
    right: 9px;
    margin-right: 16px;
}

.board-body-header .ant-dropdown {
    margin-top: -20px;
}

.board-body-header .ant-menu-horizontal .ant-menu-title-content .anticon {
    position: relative;
    bottom: 2px;
    margin-left: 4px;
    border-right: transparent !important;
    padding-right: 0 !important;
}

.board-body-header .ant-menu-horizontal .ant-menu-title-content span {
    margin-left: 4px;
    line-height: initial;
    padding-right: 12px;
}

.board-body-header .ant-menu-horizontal .ant-menu-item {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.board-body-header .ant-menu-horizontal .ant-menu-item-disabled {
    border: none !important;
}

.board-body-header,
.boards-actions {
    width: calc(100% - var(--board-sidebar-width) - 3rem + 2px);
}

.board-body-header.side-active {
    width: calc(100% - var(--board-sidebar-width) - 11.5rem);
}

.board-body-header-info-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 28px;
    border-bottom: 3px solid #f7f7f7;
    padding-bottom: 3px;
}

.board-body-header-info-right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.board-body-header-info-right svg path {
    fill: #666;
}

.board-body-header-icon svg {
    width: 22px;
}

.board-body-header-icon {
    border-radius: 15%;
    width: 33px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 2px;
}

.board-body-content {
    padding-top: 115px;
}

.board-table-wrapper {
    padding-top: 64px;
}

.board-table .ant-btn-default {
    background-color: transparent;
}

.board-table {
    position: relative;
    min-width: 99%;
}

.board-flatlist-item {
    position: absolute;
    width: 100%;
    border-right: none;
}

/* 
.board-flatlist-item:hover:not(.board-group-header) {
    background-color: #f5f5f5;
} */

.board-flat-list-row {
    position: relative;
    height: inherit;
    display: flex;
}

.board-sticky-columns-wrapper {
    display: flex;
}

/* .board-sticky-columns-wrapper button,
.board-group-header-content  button:first-child {
    display: none;
}

.board-flatlist-item:hover .board-sticky-columns-wrapper button,
.board-group-header:hover .board-group-header-content  button:first-child 
{
    display: block;
} */

.board-sticky-columns-wrapper,
.boards-list-footer-content,
.board-group-header-content,
.boards-group-button-container {
    height: inherit;
    position: sticky;
    z-index: 7;
    background-color: white;
    left: var(--board-left-offset);
}

.board-virtual-columns-wrapper {
    position: relative;
    height: inherit;
}

.board-list-header .board-column-resizer {
    visibility: hidden;
}

body:not([data-dragging]) .board-list-header:hover .board-column-resizer {
    visibility: visible;
}

.board-column-resizer {
    position: absolute;
    right: 0;
    height: 96%;
    background-color: var(--board-border-color);
    width: 5px;
    cursor: col-resize;
    transform: translateX(2px);
    border-radius: 3px;
    z-index: 4;
    touch-action: none;
}

.board-column-resizer:hover {
    background-color: #6d41be;
}

.board-flat-list-item-cell {
    touch-action: none;
}

.board-flat-list-item-cell,
.board-header-cell {
    color: #323338;
}

.board-header-cell>div:nth-child(2) {
    padding-inline: 4px;
}

.board-header-cell>div:nth-child(2):hover {
    border: 1px solid #6d41be;
    cursor: pointer;
}

.board-flat-list-item-cell,
.board-header-cell,
.boards-selection-checkbox,
.boards-row-cell,
.boards-column-add-cell {
    height: inherit;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boards-row-cell,
.boards-selection-checkbox,
.board-header-cell,
.boards-column-add-cell,
.boards-list-footer,
.boards-list-footer-content .board-row-input {
    border-right: 1px solid var(--board-border-color);
    border-bottom: 1px solid var(--board-border-color);
}


.boards-row-cell input,
.board-header-cell .ant-input,
.boards-row-cell .ant-input-number {
    height: 100%;
    padding-inline: 5px !important;
}

.boards-list-footer-content .board-add-row-sync {
    height: inherit;
    width: 100%;
    border-bottom: 1px solid var(--board-border-color);
}

.boards-sticky-header .boards-selection-checkbox,
.board-header-cell,
.boards-column-add-cell,
.board-list-header .boards-selection-checkbox {
    border-top: 1px solid var(--board-border-color);
}

.board-table .board-list-header .board-group-indicator {
    border-top-left-radius: 5px;
}

.boards-list-footer .board-group-indicator,
.board-group-collapsed .board-group-indicator {
    border-bottom-left-radius: 5px;
}

.board-cell-primary {
    display: grid;
    align-content: center;
    justify-items: center;
    grid-template-columns: var(--board-action-cell-width) var(--board-group-indicator-width) var(--board-action-cell-width) var(--board-dynamic-cell-width);
}

.board-cell-primary.candrag {
    cursor: grab;
}

.board-group-indicator {
    width: 100%;
    height: inherit;
}

.board-flat-list-row .board-group-indicator {
    grid-column-start: 2;
}

.board-cell-virtual {
    position: absolute;
}

.board-flat-list-item-cell:last-child {
    border-right: none;
}

.board-flat-list-item-cell:hover .board-header-cell-actions-wrapper {
    visibility: visible;
}

.board-header-cell,
.boards-row-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: inherit;
    width: 100%;
}

.boards-row-cell {
    position: relative;
}

.boards-row-cell.editing {
    border: 2px solid var(--color-primary);
}

.board-cell-datepicker,
.board-custom-popover-cell {
    position: absolute;
    width: 100%;
    height: 100%;
    /* padding-left: 3rem; */
    padding-top: .65rem;
    color: #666;
}

.board-cell-datepicker {
    padding: 4px;
}

.board-cell-datepicker input {
    text-align: center;
}

.board-custom-popover-content {
    min-width: 180px;
    /* padding-top: 8px; */
}

.board-popover-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.board-loading-overlay {
    position: relative;
}

.board-loading-sync {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    z-index: 1;
}

.board-loading-sync.center {
    right: 50%;
    transform: translate(50%, -50%);
}

.board-loading-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
    z-index: 2
}

.board-header-cell-empty {
    flex: 1;
}

.board-header-cell-actions-wrapper {
    display: flex;
    visibility: hidden;
    justify-content: flex-end;
    flex: 1;
    column-gap: 5px;
    padding-inline: 5px;
}

.board-header-cell-sort-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.board-header-cell-sort-wrapper .active svg {
    fill: #6d41be;
}

.board-header-cell-sort-wrapper span {
    cursor: pointer;
}

.board-header-cell-sort-wrapper svg {
    width: 12px;
    height: 12px;
}

.boards-custom-popover {
    --custom-popover-space-size: 8px;
    position: absolute;
    padding: var(--custom-popover-space-size);
    min-width: 200px;
    background-color: #ffffff;
    border-radius: var(--custom-popover-space-size);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.boards-labels {
    --custom-popover-space-size: 8px;
    /* padding: var(--custom-popover-space-size); */
    /* max-width: 600px; */
}

.boards-labels-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--custom-popover-space-size);
}

.boards-labels-header strong {
    font-size: 1rem;
}

.boards-labels-list {
    display: grid;
    grid-auto-flow: column;
    /* overflow-x: auto;
    overflow-y: hidden; */
    gap: var(--custom-popover-space-size);
}

.boards-labels-list-item:not(.label-add-btn),
.boards-labels-selection-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    height: 2rem;
    width: 180px;
    column-gap: 2px;
}

.ant-menu-item {
    cursor: pointer;
}

.boards-labels-selection-item {
    color: #fff;
    cursor: pointer;
    position: relative;
}

.boards-labels-selection-item:hover {
    opacity: 0.85;
}

.boards-labels-selection-item.selected::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border-radius: 4px;
    border: 2px solid #bbbbbb;
    /* box-shadow: 0 0 0 2px #6d41be inset; */
}

.boards-labels-list svg {
    visibility: hidden;
    cursor: move;
}

.boards-labels-list .flex:hover svg {
    visibility: visible;
}

.boards-labels-list-item-content {
    height: 100%;
    display: grid;
    column-gap: 4px;
    grid-template-columns: 22px auto;
}

.boards-labels-list-item:not(.label-add-btn) {
    border: 1px solid var(--board-border-color);
    border-radius: 4px;
}

.boards-labels-list-item .board-editable-field {
    height: 100%;
}

.boards-labels-list-item-content .label-color-box {
    height: 100%;
}

.boards-labels-footer {
    margin-top: var(--custom-popover-space-size);
    padding-top: var(--custom-popover-space-size);
    border-top: 1px solid var(--board-border-color);
}

.boards-filter-popover {
    padding: 15px;
    width: 700px;
}

.boards-filter-popover-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 20px;
}

.boards-filter-popover-list {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.boards-filters-list {
    display: grid;
    grid-template-columns: repeat(18, minmax(0, 1fr));
    column-gap: 5px;
    margin-block: 8px;
}

.boards-filter-field,
.boards-sorter-field {
    width: 100%;
}

.boards-sorter-popover {
    width: 300px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 8px;
}

.boards-sorter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.boards-sorter-list-item {
    display: grid;
    grid-template-columns: repeat(11, minmax(0, 1fr));
    column-gap: 8px;
    align-items: center;
}

.boards-sorter-list-item>div:not(:last-child) {
    grid-column: span 5 / span 5;
}

.boards-sorter-list-item>div:last-child {
    grid-column: span 1 / span 1;
    place-self: center;
}

.boards-actions {
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    background-color: #fff;
    z-index: 9;
    padding: 8px 28px;
    border-radius: 8px;
}

.boards-actions:not(.table-actions) {
    width: 100%;
}

.boards-actions.table-actions {
    position: fixed;
}

.boards-actions .ant-btn {
    box-shadow: none;
    border: none;
}

.boards-actions .ant-btn::before {
    height: 100% !important;
    top: 0 !important;
}

.boards-search {
    width: 256px;
}

.boards-sticky-header {
    position: sticky;
    background-color: white;
    z-index: 8;
}

.board-group-header {
    display: flex;
    background-color: #fff !important;
    height: inherit;
}

.board-group-header[draggable] {
    cursor: grab;
}

.board-group-header-content {
    display: grid;
    grid-template-columns: var(--board-action-cell-width) var(--board-group-indicator-width) var(--board-action-cell-width) max-content;
    font-size: 1rem;
    height: inherit;
}

.board-group-header-content button {
    justify-self: center;
    align-self: center;
}

.board-group-header-content div {
    cursor: text;
}

.boards-group-header-collapse {
    grid-column-start: 3;
}

.board-group-header-content :last-child {
    align-self: center;
}

.board-group-header-editable {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding-inline: 5px;
    border: 1px solid;
    height: calc(100% - 20%);
    border-radius: 3px;
}

.board-group-header-editable-color {
    width: 18px;
    height: 18px;
}

.board-group-header-editable-color>div,
.label-color-box>div {
    cursor: pointer;
    border-radius: 4px;
    height: 100%;
}

.board-group-header-editable .board-editable-field {
    font-size: inherit;
    color: inherit;
}

.boards-sticky-header>div {
    height: 36px;
}

.boards-list-item {
    display: flex;
    justify-content: space-between;
    gap: 1px;
    line-height: 31px;
    padding: 0;
}

.board-sidebar .ant-tree .ant-tree-indent {
    display: none;
}

.board-sidebar .ant-tree-draggable-icon {
    display: none !important;
}

.board-sidebar .board-item-icon {
    background-color: var(--color-primary);
    fill: #fff !important;
    height: 23px;
    padding: 0 2px;
    border-radius: 5px;
}

.board-sidebar .board-item-icon svg {
    fill: #fff;
    width: 17px;
}

.board-sidebar .ant-tree-switcher {
    position: relative;
    top: 5px;
}

.board-sidebar .ant-tree-draggable-icon {
    width: 15px !important;
    line-height: 32px !important;
}

.boards-list-item span {
    font-size: 13px;
}

.boards-list-item .ant-dropdown-trigger {
    position: relative;
    display: none;
    top: 8px;
}

.boards-list-item:hover .ant-dropdown-trigger {
    display: block;
}

.boards-list-footer {
    display: flex;
    height: inherit;
}

.boards-list-footer-content {
    height: inherit;
    display: grid;
    grid-template-columns: var(--board-action-cell-width) var(--board-group-indicator-width) 2rem auto;
}

.boards-list-footer-content .board-group-indicator {
    grid-column-start: 2;
    opacity: 0.6;
}

.boards-list-footer-content .board-add-row-sync {
    display: flex;
    justify-content: center;
    align-items: center;
}

.boards-list-footer-content .board-row-input {
    grid-column-start: 4;
    min-width: 200px;
    display: flex;
    padding-right: 5px;
    align-items: center;
}

.boards-list-footer-content .board-row-input button {
    text-align: left;
    border: none;
    box-shadow: none;
}

.boards-list-footer-content .board-row-input input {
    border: 1px solid transparent;
    border-radius: 4px;
    height: calc(100% - 8px);
    padding-left: 4px !important;
}

.boards-list-footer-content .board-row-input input:hover {
    border-color: var(--board-border-color);
}

.boards-list-footer-content .board-row-input input:focus {
    border-color: var(--color-primary)
}

.boards-actions-cell {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    justify-items: center;
    height: inherit;
}

.boards-icons-palette {
    z-index: 15;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 8px;
    padding: 8px;
    margin-bottom: 2px;
    border-bottom: 1px solid #e8e8e8
}

.boards-icons-palette-color {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.boards-icons-palette-color:hover {
    scale: 1.1;
}

.boards-icons-list {
    z-index: 15;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 8px;
    padding: 8px;
}

.boards-icons-list svg {
    width: 22px;
}

.boards-icons-list svg path {
    fill: #666;
}

.boards-icons-item {
    border-radius: 4px;
    cursor: pointer;
}

.boards-icons-item:hover {
    scale: 1.1;
}

/* .board-flat-list-row .board-actions-menu {
    display: none;
} */

/* .board-flat-list-row:hover .board-actions-menu {
    display: block;
} */

.boards-group-button-wrapper {
    display: flex;
    position: relative;
    top: 97%;
    height: 40px;
}

.boards-group-button-container {
    padding-left: var(--board-body-inline-padding);
}

.boards-group-button-container button {
    border: 1px solid var(--board-border-color);
}

.boards-popover-cell {
    height: inherit;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: white;
    border-bottom: inherit;
}

.boards-label-cell {
    color: #ffffff;
    border-bottom: 1px solid var(--board-border-color);
}

.boards-labels-palette {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 8px;
    padding: 8px;
    width: 200px;
}

.boards-labels-palette-color {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.boards-labels-palette-color:hover {
    scale: 1.1;
}

.boards-column-add-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.boards-people-popover-content {
    width: 250px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.boards-person-list {
    border-top: 1px solid #e4e2e2;
    padding-top: 8px;
}

.boards-person-list-item {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding-block: 6px;
    padding-left: 6px;
    border-radius: 6px;
    cursor: pointer;
}

.boards-person-list-item:hover {
    background-color: rgba(0, 0, 0, .06);
}

.boards-persons-selected-list {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.boards-person-selected-list-item {
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding-inline: 4px;
}

.boards-person-selected-list-item .ant-tag-close-icon {
    margin-inline-start: 4px;
}

.ant-avatar-group-popover .ant-popover-inner-content {
    padding: 8px;
    column-gap: 5px;
    display: flex;
}

.board-cell-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 50px;
    padding-inline: 5px;
    cursor: text;
}

.board-cell-text,
.boards-row-cell input {
    border: 1px solid transparent !important;
}

.board-cell-text:hover,
.boards-row-cell input {
    border-color: var(--board-border-color) !important;
}

.board-header-cell {
    position: relative;
}

.board-header-cell-popover {
    position: absolute;
    inset: 0;
    z-index: -1;
}

.board-dropzone-placeholder {
    position: absolute;
    height: 108px;
    width: 100%;
    left: 0;
    z-index: 10;
    top: -72px;
}

.board-dropzone {
    position: absolute;
    inset: 0;
}

.board-dropzone.columns-dropzone {
    background-color: rgba(0, 0, 0, 0.4);
}

.board-dropzone:where(.left, .top):before,
.board-dropzone:where(.right, .bottom)::after {
    content: '';
    position: absolute;
    display: block;
    background-color: #6d41be;
    border-radius: 3px;
    z-index: 9;
}

.board-dropzone.left::before,
.board-dropzone.right::after {
    width: 5px;
    height: 100%;
}

.board-dropzone.top::before,
.board-dropzone.bottom::after {
    height: 3px;
    width: 100%;
    margin-left: calc(var(--board-body-inline-padding) + var(--board-group-indicator-width));
}

.board-dropzone.left::before {
    left: -3px;
}

.board-dropzone.right::after {
    right: -3px;
}

.board-dropzone.bottom::after {
    bottom: -3px;
}

.board-dropzone-placeholder .board-dropzone::after {
    bottom: 35px;
}

.boards-preview-image-wrapper {
    position: absolute;
    top: 0;
    left: -300%;
}

.boards-preview-image {
    width: 100px;
    height: 150px;
    text-align: center;
    padding: 4px;
    border-radius: 6px;
    background: #3e00ff2b;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding-top: 8px;
}

.board-body-header-info-left {
    width: 100%;
    margin-bottom: 10px;
}

.app-sidebar nav a button {
    color: #889096;
}

.primary-cell {
    display: flex;
    justify-content: space-between;
    padding-left: .5rem;
}

.primary-cell .boards-row-detail-label {
    flex: 1;
    text-align: right;
    height: 100%;
    cursor: pointer;
}

.primary-cell .boards-row-detail-label span:last-child {
    display: none;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    padding-right: 5px;
    line-height: 1.9;
    color: #444;
    font-size: 12px;
}

.primary-cell .boards-row-detail-label>div {
    display: flex;
    height: 100%;
    justify-content: flex-end;
}

.primary-cell .boards-row-detail-label span:last-child svg {
    width: 18px;
}

.primary-cell:hover .boards-row-detail-label span:last-child {
    display: flex;
}

.board-custom-drawer {
    position: fixed;
    right: 0;
    width: 500px;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: 12;
    border-left: 1px solid #ebebeb;
    box-shadow: 1px 2px 14px #d9d9d9;
}

.update-input-placeholder {
    height: 50px;
    padding: 10px;
    cursor: text;
    background-color: #f6f3f3;
    border-radius: 4px;
}

.boards-reference-popover {
    width: 240px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.boards-reference-list {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.boards-reference-list label.ant-radio-button-wrapper {
    border-radius: 4px !important;
    border: 2px solid #e4e2e2;
}

.boards-reference-list label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border-radius: 4px !important;
    border-color: #6d41be;
}

.boards-reference-list label.ant-radio-button-wrapper::before {
    display: none;
}

.boards-reference-list-header {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    border-bottom: 1px solid #e4e2e2;
    padding-bottom: 8px;
}

.boards-reference-group-label {
    margin: 0;
}

.boards-reference-rows-list {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.boards-reference-rows-list-item {
    --border-radius: 4px;
    display: grid;
    grid-template-columns: 4px 2rem auto;
    height: 2rem;
    align-items: center;
    border: 1px solid #e4e2e2;
    border-radius: var(--border-radius);
}

.boards-reference-rows-list-item div:nth-of-type(2) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e4e2e2;
}

.boards-reference-group-indicator {
    background-color: var(--boards-reference-group-color);
    height: 100%;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.boards-reference-rows-list-item div:last-child {
    padding-inline: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.board-reference-list-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    color: #6d41be;
    border: 1px solid #e4e2e2;
    border-radius: 50%;
    width: 28px;
    height: 28px;
}

.boards-reference-cell {
    column-gap: 6px;
}

.board-update-item {
    border-radius: 8px;
    border: 1px solid #e4e2e2;
}

.board-updates-wrapper {
    max-height: calc(100vh - 210px);
    padding-bottom: 50px;
    padding-top: 10px;
    overflow: auto;
}

.boards-charts {
    padding: 10px var(--board-body-inline-padding);
}

.charts-grid-layout-item {
    border: 1px solid #e4e2e2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.boards-chart-wrapper {
    height: 100%;
    width: 100%;
}

.charts-card-header {
    justify-content: space-between;
    align-items: center;
    flex-basis: max-content;
}

/* .charts-modal-body {} */

.charts-view-wrapper,
.board-number-view-modal {
    display: grid;
    grid-template-columns: 1fr 300px;
}

.charts-numbers-list label {
    margin-inline-start: 0 !important;
}

.board-number-view-page,
.number-view-value {
    display: flex;
    justify-content: center;
    align-items: center;
}

.board-number-view-page,
.board-number-view-modal {
    height: 100%;
    width: 100%;
}

.board-number-view-modal .number-view-value {
    min-height: 300px;
}

.number-view-value {
    font-size: 4rem;
    font-weight: 600;
}

.numbers-view-columns {
    display: flex;
    flex-direction: column;
}

.numbers-view-columns label {
    margin-inline-start: 0 !important;
}

.charts-grid-layout-item.react-grid-item>.react-resizable-handle::after {
    background: #6d41be;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    transform: translate(50%, 50%);
    border: none;
    left: -1px;
    top: -2px;
    bottom: initial;
    right: initial;
    opacity: 0;
}

.charts-grid-layout-item.react-grid-item:hover>.react-resizable-handle::after {
    opacity: 1;
}

.react-grid-item.react-grid-placeholder {
    background-color: transparent !important;
    border: 1px dashed #6d41be !important;
    border-radius: 10px;
}

.people-email {
    position: relative;
    top: -5px
}

.board-table-wrapper .ant-avatar-group .ant-avatar {
    border-color: #9b9b9b;
    margin-top: 1px;
    background-color: #fff;
    color: #444;
}

.board-cell-clear-wrapper {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    z-index: 10;
    display: none;
}

:where(.boards-row-cell:not(.cell-editable), .kanban-editable-cell):hover .board-cell-clear-wrapper {
    display: block;
}

.board-cell-clear-wrapper button {
    height: 1rem !important;
    width: 1rem !important;
    padding: 0;
}

.board-cell-clear-wrapper button span {
    display: block !important;
}

.board-cell-clear-wrapper svg {
    height: 10px !important;
    width: 10px !important;
}

.boards-footer {
    position: fixed;
    justify-content: center;
    gap: 1rem;
    bottom: 5%;
    z-index: 14;
    width: 775px;
    max-width: 100%;
    margin-left: 26%;
    border-radius: 10px;
    border: 1px solid #eae9e9;
    background-color: #fff;
}

.board-sticky-columns-wrapper .board-list-summary.board-cell-primary {
    border-right: 1px solid #e4e2e2;
}

.number-summary-popover {
    width: 300px;
}

.number-functions-group {
    display: flex;
}

.number-functions-group label {
    flex: 1;
    padding-inline: 3px !important;
}

.number-units-group {
    display: flex;
    column-gap: 4px;
    justify-content: space-between;
}

.number-units-group>div:first-child {
    display: flex;
    column-gap: 2px;
}

.number-units-group>div:last-child {
    display: flex;
}

.number-units-group>div:first-child .ant-radio-group label {
    padding-inline: 6px !important;
}

.number-units-group>div:last-child.ant-radio-group label {
    padding-inline: 6px !important;
}

.cell-sync {
    position: absolute;
    right: 0;
    top: 0;
    /* top: 50%;
    transform: translateY(-50%); */
}

.cell-sync span {
    font-size: 10px;
}

.edit-main-container .ant-form-item {
    margin-bottom: 10px;
}

.boards-person-popover {
    width: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.persons-filter-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    /* margin-top: ; */
}

.person-filter-list-item {
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.person-filter-list-item.selected {
    border: 2px solid #6d41be;
    /* background-color: #e4e2e2; */
    border-radius: 50%;
}

.board-navigation-container {
    width: 100%;
    padding: 7px 0.5rem;
}

.board-navigation-container .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
}

.board-navigation-active {
    background-color: #f8f0ff;
    color: rgba(0, 0, 0, 0.88);
}

.nav-item-wrap svg {
    transition: all 0.4s;
}

.detail-upload-button>.ant-upload-select {
    padding: 0 !important;
}

.board-item-details-tab-container {
    height: 100%;
}

.board-item-details-tab-container .ant-tabs-content {
    height: 100% !important
}

.board-item-details-tab-container .ant-tabs-content .ant-tabs-tabpane-active {
    height: 100% !important
}

.boards-columns-list-item {
    display: flex;
    margin-bottom: 0.25rem;
}

.board-body .ant-input.ant-input-borderless.css-1up57fo.board-editable-field.large:focus {
    border: 1px solid #a67bf2;
}

.board-group-header .board-group-header-content div>span {
    color: #a5a5a5;
    margin-left: 10px;
    display: none;
    font-size: 13px;
}

.board-group-header:hover .board-group-header-content span {
    display: inline-block;
}

.boards-add-column-button {
    height: 2.25rem;
}

.boards-add-column-button:hover {
    background-color: var(--color-primary);
}

.boards-add-column-button:hover span {
    color: #fff;
}

.upload-full .ant-upload-select {
    display: block;
}

.board-cell-image-link {
    width: 20px;
}

.board-image-cell,
.board-cell-video-link {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-inline: 5px;
}

.board-cell-image-link:hover {
    color: var(--color-primary)
}

.board-cell-video-link {
    display: flex;
    gap: 5px;
    text-decoration: none;
}

.board-cell-video-link:hover {
    color: var(--color-primary)
}

.board-custom-popover-close-icon {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 16px;
    cursor: pointer;
}

.board-custom-popover-close-icon:hover {
    color: var(--color-primary);
}

.import-dropdown .anticon-down {
    background-color: #EFEFFB;
    color: #000;
    width: 22px;
    padding-left: 4px;
    border-radius: 3px;
    top: 38%;
    height: 22px;
}

.label-status-selection {
    display: flex;
    gap: 5px;
}

.label-status-selection>div {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 3px;
    color: #fff;
}

.label-add-btn {
    width: 182px;
}

.chart-search .ant-input-affix-wrapper {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.chart-search .ant-input-group-addon {
    display: none;
}

.chart-setting .ant-collapse-item {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 10px;
}

.chart-setting .ant-collapse-item .ant-collapse-expand-icon span {
    position: relative;
    right: 6px;
}

.chart-setting .ant-collapse-item .ant-collapse-expand-icon {
    background-color: #EFEFFB;
    border-radius: 4px;
}

.chart-setting .ant-collapse-item:last-child {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

.board-rich-text-field-wrapper {
    width: 100%;
}

.board-rich-text-field-container {
    position: relative;
    height: 85vh;
}

.board-rich-text-field-editor {
    height: 80vh !important;
}

.btn-option {
    text-align: center;
    background-color: #fff;
}

.btn-option  span {
    padding-inline: 0 !important;
    margin-inline: 0 !important;
}
