@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@font-face {
    font-family: "GT Walsheim";
    src: local("GT Walsheim"), url("./../dashboard/assets/GTWalsheimPro-Bold.woff2") format("woff")
}


@tailwind base;
@tailwind utilities;

.btn.wide {
    display: block;
    width: 100%;
}

:root {
    --color-primary: #6e41c0;
    --border-color: #F7F7F7;
}

html,
body {
    margin: 0;
}

body,
* {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
}

h1,
h2,
h3 {
    font-family: "GT Walsheim", Tahoma, serif;
}

a,
span,
p {
    font-size: 14px;
}

a {
    color: #333;
    text-decoration: none;
}

/* Buttons */
button {
    border: none;
}

.btn {
    border-radius: 5px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .1);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    padding: 4px 10px;
}

.btn-primary {
    background-color: var(--color-primary);
}

.error {
    margin-top: 10px;
    color: #f00;
}

.feature-box {
    background-image: linear-gradient(to right, #73ac7cd8, #fff, #846cb3c4);
}

.card-p-0 .ant-card-body {
    padding: 0;
}

.board-radio-style :where(.css-dev-only-do-not-override-1gi0mrs).ant-radio-wrapper .ant-radio-inner {
    background-color: #6E41C0;
}

.app-container .account-form div {
    margin-bottom: 0;
}

.app-container .account-card .ant-card-head {
    min-height: 45px;
    padding-left: 12px;
}

.app-container .account-inputs .ant-form-item {
    margin-bottom: 5px;
}

.password-input {
    margin-bottom: 10px !important;
}

.password-input .ant-row .ant-form-item-label {
    height: 34px !important;
}

/* Context Menu */
.w-context-menu {
    border-radius: 0 !important;
    width: 240px;
    padding: 0 !important;
}

.w-context-menu li>.ant-dropdown-menu-submenu-title {
    padding: 0 !important;
}

.w-context-menu li svg {
    width: 16px;
}

.w-context-menu li {
    border-radius: 0 !important;
    padding: 8px 10px !important;
}

.w-context-menu li:hover {
    background-color: var(--color-primary) !important;
}

.w-context-menu li:hover svg path {
    fill: #fff !important;
}

.w-context-menu li:hover span {
    color: #fff !important;
}

.w-context-menu li span {
    color: #434343;
}

.w-context-menu .ant-dropdown-menu-item-disabled span {
    color: #b3b3b3;
}

.w-context-menu .ant-dropdown-menu-item-disabled svg path {
    fill: #b3b3b3;
}

.btn-border {
    border: 1px solid #bdbdbd;
    box-shadow: none;
    background: #fff;
    color: #151515;
}

.env-tag {
    font-size: 11px;
    background-color: #ece2ff;
    padding: 2px 6px;
    color: #000;
    border-radius: 2px;
    cursor: default;
    position: relative;
    top: -2px;
}